<template>
  <d-input-group>
    <d-input
      v-model="day"
      type="number"
      placeholder="DD"
      min="1"
      :max="dayCannotBeInTheFuture()"
      :class="$v.day.$invalid ? 'border-danger' : 'border-success'"
      @input="updateDate"
      @change="updateDate"
    />
    <d-form-select
      v-model="month"
      :options="monthsAvailable"
      :class="month === '' ? 'border-danger' : 'border-success'"
    >
      <option :value="''" disabled>MM</option>
    </d-form-select>
    <d-input
      v-model="year"
      type="number"
      placeholder="AAAA"
      min="1900"
      :max="yearCannotBeInTheFuture()"
      :class="$v.year.$invalid ? 'border-danger' : 'border-success'"
      @input="updateDate"
      @change="updateDate"
    />
  </d-input-group>
</template>

<script>
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
import PlutonBaseMixin from 'src/components/mixins/PlutonBaseMixin'

export default {
  name: 'EasyDatepicker',
  mixins: [PlutonBaseMixin],

  data() {
    return {
      months: [
        { value: '1', text: 'Enero' },
        { value: '2', text: 'Febrero' },
        { value: '3', text: 'Marzo' },
        { value: '4', text: 'Abril' },
        { value: '5', text: 'Mayo' },
        { value: '6', text: 'Junio' },
        { value: '7', text: 'Julio' },
        { value: '8', text: 'Agosto' },
        { value: '9', text: 'Septiembre' },
        { value: '10', text: 'Octubre' },
        { value: '11', text: 'Noviembre' },
        { value: '12', text: 'Diciembre' },
      ],
      day: '',
      month: '',
      year: '',
      disabledDates: new Date(),
      date: new Date(),
    }
  },
  validations: {
    day: {
      required,
      minValue: minValue(1),
      maxValue: maxValue(31),
    },
    month: { required },
    year: {
      required,
      minValue: minValue(1900),
      maxValue: maxValue(new Date().getFullYear()),
    },
  },
  computed: {
    monthsAvailable() {
      return this.months.filter(
        (m) => m.value <= this.monthCannotBeInTheFuture()
      )
    },
  },
  watch: {
    day: {
      handler(value) {
        // console.log('activo watcher')
        if (this.day <= this.dayCannotBeInTheFuture()) {
          // console.log('activo if')
          this.day = String(value)
        } else {
          // console.log('activo else')
          this.day = String(this.dayCannotBeInTheFuture())
        }
        this.updateDate()
      },
    },
    month: {
      handler(value) {
        // console.log('activo watcher month', value)
        if (this.month < this.monthCannotBeInTheFuture()) {
          // console.log('activo if month')
          this.month = String(value)
        } else if (this.month == this.monthCannotBeInTheFuture()) {
          // console.log('soy elif month')
          this.day = this.setCorrectDay(this.day)
        } else {
          // console.log('activo else month')
          this.month = String(this.monthCannotBeInTheFuture())
          this.day = this.setCorrectDay(this.day)
        }
        this.updateDate()
      },
    },
    year: {
      handler(value) {
        // console.log('activo watcher year')
        if (this.year < this.yearCannotBeInTheFuture()) {
          // console.log('activo if year')
          this.year = String(value)
        } else {
          // console.log('activo else year')
          this.year = String(this.yearCannotBeInTheFuture())
          //   this.year = this.setCorrectYear(this.year)
          this.month = this.setCorrectMonth(this.month)
          if (this.month != '') this.day = this.setCorrectDay(this.day)
        }
        this.updateDate()
      },
    },
  },
  methods: {
    dayCannotBeInTheFuture() {
      if (
        this.year == this.disabledDates.getFullYear() &&
        this.month == this.disabledDates.getMonth() + 1
      )
        return this.disabledDates.getDate()
      return 31
    },
    monthCannotBeInTheFuture() {
      if (this.year == this.disabledDates.getFullYear())
        return this.disabledDates.getMonth() + 1
      return 12
    },
    yearCannotBeInTheFuture() {
      return this.disabledDates.getFullYear()
    },
    setCorrectDay(val) {
      if (val <= this.dayCannotBeInTheFuture()) {
        return String(val)
      }
    },
    setCorrectMonth(val) {
      if (val <= this.monthCannotBeInTheFuture()) {
        return String(val)
      }
    },
    setCorrectYear(val) {
      if (parseInt(val) < 1900) {
        return 1900
      } else if (val > this.disabledDates.getFullYear())
        return this.disabledDates.getFullYear()
      return val
    },
    updateDate() {
      // console.log('activo update', this.$v)
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.date = new Date(
          `${this.month.padStart(2, '0')}/${this.day.padStart(2, '0')}/${
            this.year
          }`
        )
        this.$emit('input', this.date)
        // console.log('emiutoo', this.date)
      } else {
        this.$emit('invalid-input', '')
      }
    },
  },
}
</script>
