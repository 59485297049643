<template>
  <div :class="['main-navbar', 'bg-white', stickyTop ? 'sticky-top' : '']">
    <d-navbar type="light" class="py-0 pr-0">
      <d-navbar-nav
        class="mr-auto mr-md-0 ml-0 ml-md-auto align-items-center flex-row"
      >
        <d-nav-item>
          <d-button
            id="notificationsHandlerButton"
            class="mx-3 py-2 isNav bg-white border-white"
            type="button"
            @click="() => (notificationsClicked = !notificationsClicked)"
          >
            <span class="sm material-icons text-accent">{{
              notifications.find((n) => n.readed == false)
                ? 'notifications_active'
                : 'notifications'
            }}</span>
          </d-button>
          <d-tooltip
            target="#notificationsHandlerButton"
            :triggers="['hover']"
            placement="left"
            >{{
              notifications.find((n) => n.readed == false)
                ? 'Tiene notificaciones sin leer'
                : 'Area de notificaciones'
            }}</d-tooltip
          >
          <div
            v-if="notificationsClicked"
            class="bg-white navbar-nav isNav notifications-container"
          >
            <d-dropdown-item
              v-for="notif in notifications"
              :key="notif.id"
              aria-modal="true"
              :to="notif.study ? `/study/${notif.study.id}/view` : null"
              class="mini-container"
            >
              <br />
              <span
                :class="['material-icons', 'text-accent']"
                style="font-size: 12px"
                >{{ !notif.readed ? 'circle' : 'radio_button_unchecked' }}</span
              >

              {{ notif.title }}
              <p
                v-for="val in splitTextContent(notif.content)"
                :key="val.id"
                class="custom-text-center-notif"
              >
                {{ val }}
              </p>
            </d-dropdown-item>
            <d-dropdown-item
              v-if="!notifications.length"
              class="mini-container"
            >
              <span
                :class="['material-icons', 'text-accent']"
                style="font-size: 15px"
                >done
              </span>
              No tiene notificaciones!</d-dropdown-item
            >
          </div>
        </d-nav-item>
        <d-nav-item>
          <img
            class="user-avatar rounded-circle mr-2"
            src="@/assets/images/avatars/default.png"
            alt="User Avatar"
          />
        </d-nav-item>
        <d-dropdown :text="dropdownText" is-nav right>
          <d-dropdown-item to="/settings">
            <i class="material-icons">&#xE8B8;</i> Configuración
          </d-dropdown-item>
          <d-dropdown-divider />
          <div @click="$auth.logout()">
            <d-dropdown-item href="#" class="text-danger">
              <i class="material-icons text-danger">&#xE879;</i> Salir
            </d-dropdown-item>
          </div>
        </d-dropdown>
      </d-navbar-nav>
      <nav class="d-sm-inline d-md-none">
        <div
          class="nav-link nav-link-icon toggle-sidebar text-center"
          @click="handleClick()"
        >
          <i class="material-icons">menu</i>
        </div>
      </nav>
    </d-navbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BREAKPOINTS } from '@/utils'

export default {
  props: {
    /**
     * Whether the main navbar should be sticky, or not.
     */
    stickyTop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dropdownClasses: '',
      notificationsClicked: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    notifications() {
      return this.$auth.user().notifications
        ? this.$auth.user().notifications.sort((a, b) => {
            // Si a es no leído y b es leído, a debe ir primero
            if (a.readed === false && b.readed === true) {
              return -1
            }
            // Si b es no leído y a es leído, b debe ir primero
            else if (b.readed === false && a.readed === true) {
              return 1
            }
            // En caso contrario, mantener el orden actual
            else {
              return 0
            }
          })
        : []
    },

    dropdownText() {
      let screenWidth = window.innerWidth
      return (
        (screenWidth > BREAKPOINTS.md &&
          this.user.firstName &&
          `${this.user.firstName} ${this.user.lastName}`) ||
        ''
      )
    },
  },
  mounted() {},
  methods: {
    handleClick() {
      this.$eventHub.$emit('toggle-sidebar')
    },
    splitTextContent(text) {
      let numbersOfSplices = text.length / 35 //cool numbers of characters :)
      var firstSlice = text.slice(0, 35)
      let array = []
      array.push(firstSlice)
      if (numbersOfSplices > 1 && numbersOfSplices > 2) {
        var secondSlice = text.slice(35, 71)
        array.push(secondSlice)
      } else if (numbersOfSplices > 2 && numbersOfSplices < 3) {
        var thirdSlice = text.slice(71, text.length)
        array.push(thirdSlice)
      }
      return array
    },
  },
}
</script>
<style>
.main-navbar .dropdown-toggle {
  padding: 20px 0;
}
.notifications-container {
  max-height: 300px; /* Ajusta la altura máxima según tus necesidades */
  overflow-y: auto;
  position: fixed;
}

/* Chrome, Safari, etc */
.notifications-container::-webkit-scrollbar {
  width: 12px;
}

.notifications-container::-webkit-scrollbar-thumb {
  background-color: var(--royal-blue);
}

/* Firefox */
.notifications-container {
  scrollbar-color: var(--royal-blue) transparent;
}
/* .mini-container {
  border: 1px solid #ccc;
  border-radius: 5%;
} */
.mini-container {
  border-top: 1px solid lightgray; /* Agrega un borde gris */
}
.custom-text-center-notif {
  margin-bottom: 0.2rem;
}
</style>
