import axios from 'axios'

const getEmptyClinicalTrial = () => ({
  firstName: '',
  gender: '',
  birthDate: '',
  lastName: '',
  ethinicity: '',
  sampleNumber: '',
  createdAt: null,
  updatedAt: null,
  rowStatus: 'E',
  id: null,
  studyId: null,
  telephoneNumber: '',
  email: '',
  province: '',
  city: '',
  healthInsurance: '',
  smokingCondition: '',
  currentStage: '',
  diagnosisStage: '',
  cancerSubtypeAtDiagnosis: '',
  previousTreatments: '',
  responseToLastTreatment: '',
  drugsUsedInLastTreatment: '',
  tumorOrganSitesOfProgressiveDisease: '',
  willingnessToParticipateClinicalTrial: '',
  patientAvailable: null,
  treatmentDropMotifs: null,
})

const getEmptyTest = () => ({
  biomarker: null,
  createdAt: '',
  dontHavePharmaEnvelopeCode: null,
  geneTestId: null,
  genes: null,
  id: null,
  pharmaEnvelopeCode: null,
  pharmaId: null,
  resultDescription: null,
  resultId: '',
  resultsFile: null,
  rowStatus: 'E',
  status: '',
  studyId: null,
  testCode: '',
  testId: null,
  updatedAt: '',
})

const getEmptyStudy = () => ({
  additionalEmails: '',
  additionalInformation: '',
  createdAt: '',
  doctor: null,
  doctorId: null,
  doctorNotes: null,
  dontHaveEnvelopeCode: false,
  envelopeCode: '',
  fromCsv: false,
  id: null,
  labId: null,
  labNotes: null,
  patient: null,
  pickupAddress: '',
  pickupLocationId: null,
  resultDescription: '',
  pickupTimeRange: '',
  primaryTumorId: null,
  rowStatus: 'E',
  sampleNumber: null,
  sampleTypeId: null,
  status: 'new',
  statusUpdatedAt: null,
  studyTests: [getEmptyTest()],
  umbrellaId: null,
  updatedAt: '',
})

export const getDefaultState = () => ({
  status: null,
  errors: {},
  studies: [],
  study: getEmptyStudy(),
  medicalOrder: getEmptyClinicalTrial(),
  umbrellaSettings: [],
  totalCount: 0,
  dirty: false,
})

const getters = {
  dirty: (state) => state.dirty,
  studies: (state) => state.studies,
  study: (state) => state.study,
  medicalOrder: (state) => state.medicalOrder,
  umbrellaSettings: (state) => state.umbrellaSettings,
  totalCount: (state) => state.totalCount,
  hasErrors: (state) => Object.entries(state.errors).length > 0,
  errors: (state) => state.errors,
  isMedicalRecordNew: (state) =>
    !(
      state.medicalOrder.id &&
      state.study.medicalRecords.find(
        (record) => record.id === state.medicalOrder.id
      )
    ),
}

const actions = {
  async loadMedicalOrderInformation({ commit }) {
    commit('REQUEST_INIT')
    try {
      commit('LOAD_MEDICAL_ORDER', null)
      commit('REQUEST_SUCCESS')
    } catch (e) {
      commit('REQUEST_ERROR', e)
    }
  },
  async editMedicalOrder({ commit }, data) {
    commit('REQUEST_INIT')
    try {
      commit('LOAD_MEDICAL_ORDER', data)
      commit('REQUEST_SUCCESS')
    } catch (error) {
      commit('REQUEST_ERROR', error)
    }
  },
  async setMedicalOrderTelephoneNumber({ commit }, value) {
    commit('SET_MEDICAL_ORDER_TELEPHONE_NUMBER', value)
  },
  async setMedicalOrderProvince({ commit }, value) {
    commit('SET_MEDICAL_ORDER_PROVINCE', value)
  },
  async setMedicalOrderHealthInsurance({ commit }, value) {
    commit('SET_MEDICAL_ORDER_HEALTH_INSURANCE', value)
  },
  async setMedicalOrderEmail({ commit }, value) {
    commit('SET_MEDICAL_ORDER_EMAIL', value)
  },
  async setMedicalOrderCity({ commit }, value) {
    commit('SET_MEDICAL_ORDER_CITY', value)
  },
  async setMedicalOrderSmoker({ commit }, value) {
    commit('SET_MEDICAL_ORDER_SMOKER', value)
  },
  async setMedicalOrderPreviousTesting({ commit }, value) {
    commit('SET_MEDICAL_ORDER_PREVIOUS_TESTING', value)
  },
  async setMedicalOrderPatientAvailable({ commit }, value) {
    commit('SET_MEDICAL_ORDER_PATIENT_AVAILABLE', value)
  },
  async setMedicalOrderTreatmentDropMotifs({ commit }, value) {
    commit('SET_MEDICAL_ORDER_TREATMENT_DROP_MOTIFS', value)
  },
  async setMedicalOrderCancerSubtypeAtDiagnosis({ commit }, value) {
    commit('SET_MEDICAL_ORDER_CANCER_SUBTYPE_AT_DIAGNOSIS', value)
  },
  async setMedicalOrderDrugsUsedInLastTreatment({ commit }, value) {
    commit('SET_MEDICAL_ORDER_DRUGS_USED_IN_LAST_TREATMENT', value)
  },
  async setMedicalOrderPreviousTreatments({ commit }, value) {
    commit('SET_MEDICAL_ORDER_PREVIOUS_TREATMENTS', value)
  },
  async setmedicalOrderDiagnosisStage({ commit }, value) {
    commit('SET_MEDICAL_ORDER_DIAGNOSIS_STAGE', value)
  },
  async setMedicalOrderResponseToLastTreatment({ commit }, value) {
    commit('SET_MEDICAL_ORDER_RESPONSE_TO_LAST_TREATMENT', value)
  },
  async setMedicalOrderTumorOrganSitesOfProgressiveDisease({ commit }, value) {
    commit('SET_MEDICAL_ORDER_TUMOR_ORGAN_SITES_OF_PROGRESSIVE_DISEASE', value)
  },
  async setMedicalOrderWillingnessToParticipateClinicalTrial(
    { commit },
    value
  ) {
    commit('SET_MEDICAL_ORDER_WILLINGNESS_TO_PARTICIPATE_CLINICAL_TRIAL', value)
  },
  async setMedicalOrderCurrentStage({ commit }, value) {
    commit('SET_MEDICAL_ORDER_CURRENT_STAGE', value)
  },
  async saveNewMedicalOrder({ commit, state }) {
    commit('REQUEST_INIT')
    try {
      await axios
        .post('/api2/study/medical_orders/save_new_medical_order', {
          ...state.medicalOrder,
        })
        .then((response) => {
          if (response.status == 200) {
            commit('CLEAR_MEDICAL_ORDER')
            commit('REQUEST_SUCCESS')
          }
        })
    } catch (error) {
      commit('REQUEST_ERROR', error)
    }
  },
  async getStudies({ commit, rootState }, payload) {
    const { newFilters = {}, page = 1, limit = 24 } = payload
    const filters = {
      ...rootState.filters,
      ...newFilters,
    }

    commit('REQUEST_INIT')
    try {
      const { totalCount, studies } = (
        await axios.get(
          `/api2/study/?filters=${encodeURIComponent(
            JSON.stringify(filters)
          )}&page=${page}&limit=${limit}`
        )
      ).data
      commit('SET_STUDIES', studies)
      commit('SET_TOTAL_COUNT', totalCount)
      commit('REQUEST_SUCCESS')
    } catch (err) {
      commit('REQUEST_ERROR', err)
    }
  },
  async getStudy({ commit }, studyId) {
    commit('REQUEST_INIT')
    try {
      let study = (await axios.get(`/api2/study/${studyId}`)).data
      commit('REQUEST_SUCCESS')
      if (study) commit('SET_STUDY', study)
      else commit('SET_STUDY', getEmptyStudy())
    } catch (error) {
      commit('REQUEST_ERROR', error)
    }
  },
  setStudyStatus({ commit }, status) {
    commit('SET_STUDY_STATUS', status)
  },
  setLabNotes({ commit }, text) {
    commit('SET_LAB_NOTES', text)
  },
  setAdittionalInformation({ commit }, text) {
    commit('SET_ADITIONAL_INFORMATION', text)
  },
  setSampleNumber({ commit }, number) {
    commit('SET_SAMPLE_NUMBER', number)
  },
  setPickupTimeRange({ commit }, timeOption) {
    commit('SET_PICKUP_TIME_RANGE', timeOption)
  },
  setPickupAdress({ commit }, text) {
    commit('SET_PICKUP_ADRESS', text)
  },
  setAdittionalEmails({ commit }, mails) {
    commit('SET_ADITIONAL_EMAILS', mails)
  },

  setStudyTestDescription({ commit, state }, studyId) {
    let testIndex = state.study.studyTests.indexOf(
      state.study.studyTests.find((st) => st.id == studyId)
    )
    commit('SET_STUDYTEST_DESCRIPTION', testIndex)
  },
  async editStudy({ commit, state }, studyId) {
    commit('REQUEST_INIT')
    try {
      const study = (
        await axios.put(`/api2/study/${studyId}`, { ...state.study })
      ).data
      commit('REQUEST_SUCCESS')
      const studies = [...state.studies]
      const index = studies.findIndex((s) => s.id === studyId)
      studies[index] = study
      commit('SET_STUDY', study)
    } catch (error) {
      commit('REQUEST_ERROR', error)
    }
  },
  async getUmbrellaTestSettings({ commit }) {
    commit('REQUEST_INIT')
    try {
      const settings = (await axios.get('/api2/settings/umbrella_test')).data
      commit('REQUEST_SUCCESS')
      commit('SET_UMBRELLA_TEST_SETTINGS', settings)
    } catch (error) {
      commit('REQUEST_ERROR')
    }
  },
  // addDrugsUsedForPriorTreatments() {
  //   let firstSplit = JSON.parse(state.medicalOrder.drugsUsedForPriorTreatment.split(','))
  //   for (let index = 0; index < firstSplit.length; index++) {
  //     let drugWrited = firstSplit[index]
  //     if (drugWrited === '' || drugWrited === ' ') continue
  //     else if (drugWrited.startsWith(' ') || drugWrited.endsWith(' ')) {
  //       let drugProcessed = drugWrited.split(' ')[1]
  //       if (
  //         !state.medicalOrder.drugsUsedInLastTreatment.includes(
  //           drugProcessed
  //         )
  //       ) {
  //         state.medicalOrder.drugsUsedInLastTreatment.push(
  //           drugProcessed
  //         )
  //       }
  //     } else {
  //       if (
  //         !this.current_item.patient.drugs_used_for_prior_treatment.includes(
  //           drugWrited
  //         )
  //       ) {
  //         this.current_item.patient.drugs_used_for_prior_treatment.push(
  //           drugWrited
  //         )
  //       }
  //     }
  //   }
  // },
}

const mutations = {
  REQUEST_INIT(state) {
    state.status = 'loading'
  },
  REQUEST_SUCCESS(state) {
    state.status = 'success'
    state.errors = {}
    state.dirty = false
  },
  REQUEST_ERROR(state, message) {
    state.status = 'error'
    state.errors = message
  },
  SET_STUDIES(state, studies) {
    state.studies = studies
  },
  SET_STUDY(state, study) {
    state.study = study
  },
  SET_TOTAL_COUNT(state, totalCount) {
    state.totalCount = totalCount
  },
  SET_STUDY_STATUS(state, status) {
    state.dirty = true
    state.study.status = status
  },
  SET_STUDYTEST_DESCRIPTION(state, testIndex, description) {
    state.dirty = true
    state.study.studyTests[testIndex].resultDescription = description
  },
  SET_ADITIONAL_INFORMATION(state, text) {
    state.dirty = true
    state.study.additionalInformation = text
  },
  SET_LAB_NOTES(state, text) {
    state.dirty = true
    state.study.labNotes = text
  },
  SET_SAMPLE_NUMBER(state, number) {
    state.dirty = true
    state.study.sampleNumber = number
  },
  SET_PICKUP_TIME_RANGE(state, timeOption) {
    state.dirty = true
    state.study.pickupTimeRange = timeOption
  },
  SET_PICKUP_ADRESS(state, text) {
    state.dirty = true
    state.study.pickupAddress = text
  },
  SET_ADITIONAL_EMAILS(state, mails) {
    state.dirty = true
    state.study.additionalEmails = mails
  },
  SET_UMBRELLA_TEST_SETTINGS(state, settings) {
    state.dirty = true
    state.umbrellaSettings = settings
  },
  LOAD_MEDICAL_ORDER(state, data) {
    state.dirty = true
    var copyPatient = JSON.parse(JSON.stringify(state.study.patient))
    if (data) {
      state.medicalOrder = { ...data }
      state.medicalOrder.sampleNumber = state.study.sampleNumber
      state.medicalOrder.firstName = state.study.patient.firstName
      state.medicalOrder.lastName = state.study.patient.lastName
      state.medicalOrder.ethinicity = state.study.patient.ethinicity
      state.medicalOrder.gender = state.study.patient.gender
      state.medicalOrder.primaryTumorId = state.study.primaryTumorId
      state.medicalOrder.sampleTypeId = state.study.sampleTypeId
      state.medicalOrder.birthDate = copyPatient.birthDate
      state.medicalOrder.patientAvailable = state.medicalOrder
        .treatmentDropMotifs
        ? false
        : true
      return
    }
    state.medicalOrder.id = null
    state.medicalOrder.firstName = copyPatient.firstName
    state.medicalOrder.lastName = copyPatient.lastName
    state.medicalOrder.gender = copyPatient.gender
    state.medicalOrder.ethinicity = copyPatient.ethinicity
    state.medicalOrder.email = copyPatient.email
    state.medicalOrder.healthInsurance = copyPatient.insuranceDetails
    state.medicalOrder.telephoneNumber = copyPatient.telephone
    state.medicalOrder.province = copyPatient.administrativeAreaLevel1
    state.medicalOrder.city = copyPatient.administrativeAreaLevel2
    state.medicalOrder.responseToLastTreatment = ''
    state.medicalOrder.drugsUsedForPriorTreatment =
      copyPatient.drugsUsedForPriorTreatment
    state.medicalOrder.drugsUsedInLastTreatment = JSON.parse(
      copyPatient.drugsUsedForPriorTreatment
    ).join(',')
    state.medicalOrder.tumorOrganSitesOfProgressiveDisease = ''
    state.medicalOrder.willingnessToParticipateClinicalTrial = ''
    state.medicalOrder.sampleNumber = state.study.sampleNumber
    state.medicalOrder.primaryTumorId = state.study.primaryTumorId
    state.medicalOrder.sampleTypeId = state.study.sampleTypeId
    state.medicalOrder.studyId = state.study.id
    state.medicalOrder.previousTesting = copyPatient.previousTesting
    state.medicalOrder.smokingCondition = copyPatient.smoker
    state.medicalOrder.currentStage = copyPatient.currentStage
    state.medicalOrder.cancerSubtypeAtDiagnosis =
      copyPatient.cancerSubtypeAtDiagnosis
    state.medicalOrder.previousTreatments = copyPatient.previousTreatments
    state.medicalOrder.diagnosisStage = copyPatient.diagnosisStage
    state.medicalOrder.birthDate = copyPatient.birthDate
    state.medicalOrder.patientAvailable = null
  },
  CLEAR_MEDICAL_ORDER(state) {
    state.medicalOrder = getEmptyClinicalTrial()
  },
  SET_MEDICAL_ORDER_CURRENT_STAGE(state, value) {
    state.dirty = true
    state.medicalOrder.currentStage = value
  },
  SET_MEDICAL_ORDER_TELEPHONE_NUMBER(state, value) {
    state.dirty = true
    state.medicalOrder.telephoneNumber = value
  },
  SET_MEDICAL_ORDER_PROVINCE(state, value) {
    state.dirty = true
    state.medicalOrder.province = value
  },
  SET_MEDICAL_ORDER_HEALTH_INSURANCE(state, value) {
    state.dirty = true
    state.medicalOrder.healthInsurance = value
  },
  SET_MEDICAL_ORDER_EMAIL(state, value) {
    state.dirty = true
    state.medicalOrder.email = value
  },
  SET_MEDICAL_ORDER_CITY(state, value) {
    state.dirty = true
    state.medicalOrder.city = value
  },
  SET_MEDICAL_ORDER_SMOKER(state, value) {
    state.dirty = true
    state.medicalOrder.smokingCondition = value
  },
  SET_MEDICAL_ORDER_PREVIOUS_TESTING(state, value) {
    state.dirty = true
    state.medicalOrder.previousTesting = value
  },
  SET_MEDICAL_ORDER_PATIENT_AVAILABLE(state, value) {
    state.dirty = true
    state.medicalOrder.patientAvailable = value
  },
  SET_MEDICAL_ORDER_TREATMENT_DROP_MOTIFS(state, value) {
    state.dirty = true
    state.medicalOrder.treatmentDropMotifs = value
  },
  SET_MEDICAL_ORDER_CANCER_SUBTYPE_AT_DIAGNOSIS(state, value) {
    state.dirty = true
    state.medicalOrder.cancerSubtypeAtDiagnosis = value
  },
  SET_MEDICAL_ORDER_DRUGS_USED_IN_LAST_TREATMENT(state, value) {
    state.dirty = true
    state.medicalOrder.drugsUsedInLastTreatment = value
  },
  SET_MEDICAL_ORDER_PREVIOUS_TREATMENTS(state, value) {
    state.dirty = true
    state.medicalOrder.previousTreatments = value
  },
  SET_MEDICAL_ORDER_DIAGNOSIS_STAGE(state, value) {
    state.dirty = true
    state.medicalOrder.diagnosisStage = value
  },
  SET_MEDICAL_ORDER_RESPONSE_TO_LAST_TREATMENT(state, value) {
    state.dirty = true
    state.medicalOrder.responseToLastTreatment = value
  },
  SET_MEDICAL_ORDER_TUMOR_ORGAN_SITES_OF_PROGRESSIVE_DISEASE(state, value) {
    state.dirty = true
    state.medicalOrder.tumorOrganSitesOfProgressiveDisease = value
  },
  SET_MEDICAL_ORDER_WILLINGNESS_TO_PARTICIPATE_CLINICAL_TRIAL(state, value) {
    state.dirty = true
    state.medicalOrder.willingnessToParticipateClinicalTrial = value
  },
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  actions,
  mutations,
}
