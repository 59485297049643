<template>
  <div class="form-group">
    <form-label required>
      {{ label }}
    </form-label>
    <d-row>
      <d-col
        v-for="test in tests"
        :key="test.code"
        :class="{
          'test-picker': true,
          'test-picker-active': isTestSelected(test),
        }"
        sm="6"
      >
        <div class="p-2 mb-1 mt-1 test-picker-block">
          <d-row class="align-items-center">
            <d-col sm="12" md="4">
              <d-form-checkbox
                :id="test.code"
                v-model="selectedTests"
                :value="test.code"
                :disabled="checkOnePharmaIsDisabled(test.code, test)"
              >
                <h6
                  v-if="!test.allowedPharmas.find((ph) => ph.diagnosisSetting)"
                  class="m-0 p-0 d-inline-block"
                >
                  {{ test.name }}
                </h6>
                <h6
                  v-else-if="
                    test.allowedPharmas.find((ph) => ph.diagnosisSetting)
                  "
                  class="m-0 p-0 d-inline-block"
                >
                  {{ currentlySelectedPharmaCustomName(test) }}
                </h6>
                <span v-if="isRecentBio(test.code)">
                  <svg
                    id="tooltip"
                    width="30"
                    class="ml-2"
                    height="12"
                    viewBox="0 0 25 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.8979 10.8675L22.0366 5.9025L24.8687 1.14C24.936 1.02651 24.9727 0.896703 24.9751 0.763858C24.9775 0.631014 24.9455 0.499901 24.8823 0.383934C24.8192 0.267968 24.7273 0.171311 24.6159 0.103852C24.5045 0.0363923 24.3776 0.000552859 24.2483 0H1.45986C1.07268 0 0.701361 0.158036 0.427584 0.43934C0.153806 0.720645 0 1.10218 0 1.5L0 10.5C0 10.8978 0.153806 11.2794 0.427584 11.5607C0.701361 11.842 1.07268 12 1.45986 12H24.2702C24.399 12 24.5256 11.9649 24.6369 11.8984C24.7483 11.8318 24.8406 11.7362 24.9043 11.6212C24.9681 11.5061 25.0011 11.3758 25 11.2435C24.9988 11.1111 24.9636 10.9814 24.8979 10.8675ZM7.67157 8.385H6.85405L4.47448 5.13V8.3925H3.64965V3.75H4.47448L6.86135 7.0125V3.75H7.67887L7.67157 8.385ZM12.292 4.5H9.71538V5.6175H12.0512V6.3675H9.71538V7.575H12.292V8.325H8.89056V3.75H12.2847L12.292 4.5ZM18.3432 8.37H17.5183L16.3869 4.9275L15.2556 8.385H14.438L12.9782 3.75H13.8687L14.8322 7.0725L15.9417 3.75H16.8322L17.8979 7.0725L18.8687 3.75H19.7665L18.3432 8.37Z"
                      fill="#FFB800"
                    />
                  </svg>
                  <d-tooltip target="#tooltip" container="true">
                    Hemos habilitado un nuevo biomarcador.
                  </d-tooltip>
                </span>
              </d-form-checkbox>
            </d-col>
            <d-col sm="12" md="8">
              <d-form-select
                v-if="isTestSelected(test)"
                id="pharmaSelect"
                :value="isTestSelected(test).pharmaId"
                class="pharma"
                :options="pharmaOptionsFor(test)"
                :class="{ 'is-invalid': !isTestSelected(test).pharmaId }"
                @change="setTestPharma({ code: test.code, value: $event })"
              >
                <option :value="null" disabled>Seleccione programa</option>
                <option
                  v-if="
                    testPerPharmaUnavailableTests.find(
                      (opt) => opt.testCode == test.code
                    )
                  "
                  :value="null"
                  disabled
                >
                  {{
                    testPerPharmaUnavailableTests
                      .filter((opt) => opt.testCode == test.code)
                      .map((val) => val.pharma.name + '*')
                      .join(', ')
                  }}
                </option>
              </d-form-select>
            </d-col>
          </d-row>
        </div>
      </d-col>
    </d-row>
    <p v-if="!testsAreValid" class="text-center text-danger">
      Faltan seleccionar programas para algunos estudios
    </p>
    <p
      v-if="
        testPerPharmaUnavailableTests.length &&
        tests.find((test) =>
          testPerPharmaUnavailableTests.some((t) => t.testCode === test.code)
        ) &&
        !!testPerPharmaUnavailableTests.find((t) =>
          t.pharma.caps.some((cap) => cap.caps !== 0)
        )
      "
      class="text-accent"
    >
      El cupo de tests:
      {{
        testPerPharmaUnavailableTests
          .filter((t) => t.pharma.caps.some((cap) => cap.caps !== 0))
          .map((val) => val.testCode.toUpperCase() + `(${val.pharma.name})`)
          .join(', ')
      }}
      asignado por el/los sponsor/s para este mes han llegado al máximo
      permitido. Para solicitarlos de forma privada puede enviar mail a:
      <a class="text-accent" href="mailto:presupuesto@biomakers.net">
        presupuesto@biomakers.net</a
      >
      junto a la orden médica, para poder acercarle un presupuesto.
    </p>
  </div>
</template>
<script>
import FormLabel from './Label'
import { shuffle } from '../../utils'
import axios from 'axios'

export default {
  name: 'TestPicker',
  components: {
    FormLabel,
  },
  props: {
    tests: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Estudios a realizar',
    },
    unavailableTests: {
      type: Array,
      default: () => [],
    },
    primaryTumorId: {
      type: Number,
      default: () => null,
      required: true,
    },
  },
  data() {
    return {
      selectedTests: [], // this exists only to leverage vue's checkbox models
      selectedTestsWithPharmas: [],
      recentBiomakers: [],
      testPerPharmaUnavailableTests: [],
    }
  },
  computed: {
    testsAreValid() {
      return this.selectedTestsWithPharmas.every((item) => item.pharmaId)
    },
  },
  watch: {
    selectedTests: {
      handler: function (newTests) {
        const filteredData = newTests.map((testCode) => {
          const existingItem = this.isTestSelected({ code: testCode })
          const biomarker = this.tests.find(
            (biomarker) => biomarker.code === testCode
          )

          if (!existingItem)
            return {
              testCode,
              testId: biomarker.id,
              pharmaId: null,
              requiresValidationWithNewApi: null,
            }
          return {
            ...existingItem,
            testId: biomarker.id,
            requiresValidationWithNewApi: biomarker.allowedPharmas.find(
              (ph) => ph.id == existingItem.pharmaId
            )
              ? biomarker.allowedPharmas.find(
                  (ph) => ph.id == existingItem.pharmaId
                ).validateEnvelopeWithNewApi
              : null,
          }
        })
        this.selectedTestsWithPharmas = [...filteredData]
      },
      deep: true,
    },
    selectedTestsWithPharmas: {
      handler: function (value, oldValue) {
        let processedValues = []
        if (value.length === oldValue.length) {
          //tengo igual numero de test, solo selecciono farma
          value.forEach(({ testId, testCode, pharmaId }) => {
            if (pharmaId !== null) {
              processedValues.push({
                testId: testId,
                testCode: testCode,
                pharmaId: pharmaId,
                requiresValidationWithNewApi: this.tests
                  .find((test) => test.code == testCode)
                  .allowedPharmas.find((ph) => ph.id == pharmaId)
                  .requiresValidationWithNewApi,
                validateEnvelopeWithNewApi: this.tests
                  .find((test) => test.code == testCode)
                  .allowedPharmas.find((ph) => ph.id == pharmaId)
                  .validateEnvelopeWithNewApi,
              })
            } else {
              processedValues.push({
                testId: testId,
                testCode: testCode,
                pharmaId: pharmaId,
                requiresValidationWithNewApi: null,
                validateEnvelopeWithNewApi: null,
              })
            }
          })
          this.$emit('tests-changed', processedValues)
        } else if (oldValue.length > value.length) {
          // remuevo un test
          let removedValue = oldValue.find(
            (test) =>
              value.map((val) => val.testCode).includes(test.testCode) === false
          )
          this.$emit('remove-test', removedValue)
        } else if (oldValue.length < value.length) {
          // remuevo un test
          let addedValue = value.find(
            (test) =>
              oldValue.map((val) => val.testCode).includes(test.testCode) ===
              false
          )
          this.$emit('add-test', addedValue)
        }
      },
      deep: true,
    },
    tests: {
      handler(newValue) {
        this.setUpTests(newValue)
        this.unselectSpecificTest('ros1')
      },
    },
  },
  mounted() {
    this.setUpTests(this.tests)
    this.unselectSpecificTest('ros1')
  },

  async created() {
    const result = await axios
      .get(`/api2/biomarker/recent`)
      .then((response) => response.data)
    result.forEach((elem) => this.recentBiomakers.push(elem.code))
  },

  methods: {
    checkOnePharmaIsDisabled(testCode, test) {
      try {
        if (
          this.pharmaOptionsFor(test).length == 0 &&
          this.testPerPharmaUnavailableTests.some((t) => t.testCode == testCode)
        ) {
          this.unselectSpecificTest(testCode)
          return true
        }
      } catch (error) {
        return false
      }
    },
    isDisabledThisTest(code) {
      let thisTestHasCaps = this.unavailableTests.find((test) =>
        test.caps.length > 0 &&
        test.code == code &&
        test.allowedPharmas.find(
          (pharma) => pharma.caps && pharma.id == this.isTestSelected({ code })
        ) != undefined
          ? test.allowedPharmas.find(
              (pharma) =>
                pharma.caps &&
                pharma.id == this.isTestSelected({ code }).pharmaId
            )
          : null
      )
      if (!thisTestHasCaps) return null
      if (
        !this.testPerPharmaUnavailableTests.find(
          (test) => test.code == thisTestHasCaps.code
        )
      ) {
        this.testPerPharmaUnavailableTests.push(thisTestHasCaps)
        this.disabledPharmaOptionsForThisTest(code)
      }
      return thisTestHasCaps.allowedPharmas
        .find((ph) => ph.caps)
        .caps.some((cap) => cap.numberOfAvailableTests <= 0)
    },
    disabledPharmaOptionsForThisTest(code) {
      if (!this.isDisabledThisTest(code)) return null
      return this.testPerPharmaUnavailableTests
        .map((val) => val.allowedPharmas)[0]
        .filter(
          (pharmas) =>
            //eslint-disable-next-line no-prototype-builtins
            pharmas.hasOwnProperty('caps') &&
            pharmas.caps.some((cap) => cap.numberOfAvailableTests <= 0)
        )
        .map((pharma) => ({
          ...pharma,
          value: pharma.id,
          text: pharma.name,
        }))
    },
    setUpTests(tests) {
      this.selectedTests = []
      this.selectedTestsWithPharmas = []
      if (
        tests.every((biomarker) => biomarker.allowedPharmas.length === 1) &&
        new Set(tests.map((biomarker) => biomarker.allowedPharmas[0].id)).size >
          1
      ) {
        tests.forEach(({ id, code, pharmaId }) => {
          this.selectedTestsWithPharmas.push({
            testId: id,
            testCode: code,
            pharmaId: pharmaId,
          })
        })
        return
      }
      tests.forEach(({ id, code }) => {
        this.selectedTests.push(code)
        this.selectedTestsWithPharmas.push({
          testId: id,
          testCode: code,
          pharmaId: null,
        })
      })
    },
    unselectSpecificTest(testCode) {
      let idx = this.selectedTests.findIndex((code) => code === testCode)
      let idxPharma = this.selectedTestsWithPharmas.findIndex(
        (test) => test.code === testCode
      )
      if (idx !== -1) {
        this.selectedTests.splice(idx, 1)
      } else if (idxPharma !== -1) {
        this.selectedTestsWithPharmas.splice(idxPharma, 1)
      }
    },
    isRecentBio(testCode) {
      return JSON.parse(JSON.stringify(this.recentBiomakers)).includes(testCode)
    },

    pharmaOptionsFor({ code, allowedPharmas }) {
      this.isDisabledThisTest(code)
      let processAllowedPharmas = allowedPharmas.filter(
        (ph) => ph.diagnosisSetting
      ).length
        ? allowedPharmas.filter((ph) => ph.diagnosisSetting)
        : allowedPharmas
      let pharmasWithoutCaps = []
      for (let index = 0; index < this.unavailableTests.length; index++) {
        const element = this.unavailableTests[index]
        if (element.caps) {
          for (let idx = 0; idx < element.allowedPharmas.length; idx++) {
            const element2 = element.allowedPharmas[idx]
            if (
              element2.caps &&
              element2.caps.some((cap) => cap.numberOfAvailableTests <= 0) &&
              element.code === code &&
              element2.customSettings.find(
                (cs) => cs.primaryTumorId === this.primaryTumorId
              )
            ) {
              if (element2.caps.length > 1) {
                let userCap = element2.caps.find((c) => c.userId != null)
                if (userCap) element2.caps = [userCap]
              }
              let objectToPush =
                element2.caps.find((cap) => cap.numberOfAvailableTests <= 0) !=
                undefined
                  ? { testCode: element.code, pharma: element2 }
                  : null
              if (!pharmasWithoutCaps.includes(element2)) {
                pharmasWithoutCaps.push(element2)
              }
              if (
                objectToPush != null &&
                !this.testPerPharmaUnavailableTests.find(
                  (t) => JSON.stringify(t) == JSON.stringify(objectToPush)
                )
              ) {
                this.testPerPharmaUnavailableTests.push(objectToPush)
              }
            }
          }
        }
      }

      let pharmas = processAllowedPharmas
        .filter((ph) => !pharmasWithoutCaps.some((p) => p.code == ph.code))
        .map((pharma) => ({
          ...pharma,
          value: pharma.id,
          text: pharma.name,
        }))
      if (pharmas.length === 1) {
        this.isTestSelected({ code }).pharmaId = pharmas[0].id
        this.isTestSelected({ code }).requiresValidationWithNewApi = this.tests
          .find((test) => test.code == code)
          .allowedPharmas.find(
            (ph) => ph.id == pharmas[0].id
          ).validateEnvelopeWithNewApi
      }
      if (pharmas.length > 1) pharmas = shuffle(pharmas)
      return pharmas
    },
    currentlySelectedPharmaCustomName(test) {
      let actualTest = this.selectedTestsWithPharmas.find(
        (t) => t.testCode === test.code
      )
        ? this.selectedTestsWithPharmas.find((t) => t.testCode === test.code)
        : null
      let name =
        actualTest && actualTest.pharmaId != undefined
          ? test.allowedPharmas.find(
              (ph) =>
                ph.diagnosisSetting &&
                ph.diagnosisSetting.pharmaId == actualTest.pharmaId
            ).diagnosisSetting.customName
          : test.name
      return name
    },
    isTestSelected({ code }) {
      return this.selectedTestsWithPharmas.find(
        (item) => item.testCode === code
      )
    },
    setTestPharma({ code, value }) {
      const idx = this.selectedTestsWithPharmas.findIndex(
        (test) => test.testCode === code
      )
      if (this.selectedTestsWithPharmas[idx].pharmaId !== value)
        delete this.selectedTestsWithPharmas[idx].customEnvelopeCode
      const newPharmas = [...this.selectedTestsWithPharmas]
      newPharmas.splice(idx, 1, {
        ...newPharmas[idx],
        pharmaId: value,
      })
      this.selectedTestsWithPharmas = newPharmas
    },
  },
}
</script>

<style lang="css">
custom-active {
  background-color: #f9f9ff;
  border: 1px solid #afa8f3;
}
.test-picker .test-picker-block {
  border: 1px solid #efedfd;
  border-radius: 4px;
  box-sizing: border-box;
}

.test-picker-active .test-picker-block {
  background-color: #f9f9ff;
  border: 1px solid #afa8f3;
}

.test-picker-active
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border: 1px solid #fff;
}
</style>
