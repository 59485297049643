<template>
  <div>
    <!-- Page Header -->
    <d-row no-gutters class="page-header py-4">
      <!-- Page Header - Title -->
      <d-col col sm="4" class="text-center text-sm-left mb-0 mb-sm-0">
        <h3 class="page-title">Configuración</h3>
      </d-col>
    </d-row>

    <d-row>
      <d-col sm="12">
        <div v-if="isDoctor()">
          <d-card class="card-small mb-4">
            <d-card-header class="border-bottom">
              <h6 class="m-0">Datos Personales</h6>
            </d-card-header>
            <d-card-body>
              <div class="form-group">
                <form-label required> Nombre </form-label>
                <d-input
                  v-model="personal_details.first_name"
                  type="text"
                  placeholder="Nombre"
                />
                <d-form-invalid-feedback
                  v-if="$v.personal_details.first_name.$invalid"
                  :force-show="true"
                >
                  Este campo es obligatorio
                </d-form-invalid-feedback>
              </div>
              <div class="form-group">
                <form-label required> Apellido </form-label>
                <d-input
                  v-model="personal_details.last_name"
                  type="text"
                  placeholder="Apellido"
                />
                <d-form-invalid-feedback
                  v-if="$v.personal_details.last_name.$invalid"
                  :force-show="true"
                >
                  Este campo es obligatorio
                </d-form-invalid-feedback>
              </div>
              <div class="form-group">
                <form-label required> Teléfono </form-label>
                <d-input
                  v-model="personal_details.tel_mobile"
                  type="text"
                  placeholder="Teléfono Celular"
                />
                <d-form-invalid-feedback
                  v-if="$v.personal_details.tel_mobile.$invalid"
                  :force-show="true"
                >
                  Este campo es obligatorio
                </d-form-invalid-feedback>
              </div>

              <!-- Phone Group -->
              <div
                v-show="personal_additional_phones_count > 0"
                class="form-group"
              >
                <form-label> Teléfono Alternativo 1 </form-label>
                <d-input
                  v-model="personal_details.tel_additional_1"
                  type="text"
                  placeholder="Teléfono"
                />
              </div>
              <div
                v-show="personal_additional_phones_count > 1"
                class="form-group"
              >
                <form-label> Teléfono Alternativo 2 </form-label>
                <d-input
                  v-model="personal_details.tel_additional_2"
                  type="text"
                  placeholder="Teléfono"
                />
              </div>
              <div
                v-show="personal_additional_phones_count > 2"
                class="form-group"
              >
                <form-label> Teléfono Alternativo 3 </form-label>
                <d-input
                  v-model="personal_details.tel_additional_3"
                  type="text"
                  placeholder="Teléfono"
                />
              </div>
              <div
                v-show="personal_additional_phones_count > 3"
                class="form-group"
              >
                <form-label> Teléfono Alternativo 4 </form-label>
                <d-input
                  v-model="personal_details.tel_additional_4"
                  type="text"
                  placeholder="Teléfono"
                />
              </div>
              <div
                v-show="personal_additional_phones_count > 4"
                class="form-group"
              >
                <form-label> Teléfono Alternativo 5 </form-label>
                <d-input
                  v-model="personal_details.tel_additional_5"
                  type="text"
                  placeholder="Teléfono"
                />
              </div>

              <button
                v-show="personal_additional_phones_count < 5"
                class="btn btn-light btn-sm mb-3 pull-right"
                @click="personal_additional_phones_count++"
              >
                + Agregar otro teléfono (optativo)
              </button>
              <!-- End Phone Group -->

              <div class="form-group">
                <form-label required> País </form-label>
                <d-form-select
                  v-model="personal_details.country_id"
                  :options="countriesAsOptions"
                  placeholder="País"
                  name="country"
                />
                <d-form-invalid-feedback
                  v-if="$v.personal_details.country_id.$invalid"
                  :force-show="true"
                >
                  Este campo es obligatorio
                </d-form-invalid-feedback>
              </div>

              <div class="form-group">
                <form-label required> Matrícula Nacional </form-label>
                <d-input
                  v-model="personal_details.national_license"
                  type="text"
                  placeholder="Matrícula Nacional"
                />
                <d-form-invalid-feedback
                  v-if="$v.personal_details.national_license.$invalid"
                  :force-show="true"
                >
                  Este campo es obligatorio
                </d-form-invalid-feedback>
              </div>
              <div class="form-group">
                <label>Matrícula Provincial (si aplica)</label>
                <d-input
                  v-model="personal_details.state_license"
                  type="text"
                  placeholder="Matrícula Provincial (si aplica)"
                />
              </div>
              <p class="text-right">
                <button
                  type="button"
                  outline
                  class="btn btn-pill btn-accent"
                  @click.stop.prevent="savePersonalDetails()"
                >
                  Guardar Cambios
                </button>
              </p>
            </d-card-body>
          </d-card>

          <d-card class="card-small mb-4">
            <d-card-header class="border-bottom">
              <h6 class="m-0">Puntos de Retiro</h6>
            </d-card-header>
            <d-card-body>
              <pickup-locations
                v-if="pickup_locations.length"
                ref="pickup_locations"
                v-model="pickup_locations"
                :country="currentCountryName"
              />
              <p class="text-right">
                <button
                  type="button"
                  outline
                  class="btn btn-pill btn-accent"
                  @click.stop.prevent="savePickupLocations()"
                >
                  Guardar Cambios
                </button>
              </p>
            </d-card-body>
          </d-card>
        </div>

        <d-card class="card-small mb-4">
          <d-card-header class="border-bottom">
            <h6 class="m-0">Cambio de contraseña</h6>
          </d-card-header>
          <d-card-body>
            <div class="form-group">
              <label>Contraseña actual</label>
              <d-input
                v-model="password_change.current_password"
                type="password"
                placeholder="Contraseña actual"
              />
              <d-form-invalid-feedback
                >Este campo es obligatorio</d-form-invalid-feedback
              >
            </div>
            <password-entry
              v-model="password_change.password"
              label="Nueva Contraseña"
              label2="Repita la nueva contraseña"
              change
              :param_busq="busq"
              @password_entered="get_password"
            />
            <p class="text-right">
              <button
                type="button"
                outline
                class="btn btn-pill btn-accent"
                :disabled="change_btn"
                @click="changePassword()"
              >
                Cambiar Contraseña
              </button>
            </p>
          </d-card-body>
        </d-card>

        <d-modal v-if="showModal">
          <d-modal-body class="p-4"><span v-html="msg" /></d-modal-body>
          <d-modal-footer class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="() => (showModal = false)"
            >
              Cerrar
            </button>
          </d-modal-footer>
        </d-modal>
      </d-col>
    </d-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { required } from 'vuelidate/lib/validators'
import FormLabel from '@/components/Form/Label'
import PlutonBaseMixin from '@/components/mixins/PlutonBaseMixin'
import PickupLocations from '@/components/PickupLocations.vue'
import PasswordEntry from '@/components/Form/PasswordEntry.vue'

export default {
  name: 'Settings',
  components: { FormLabel, PickupLocations, PasswordEntry },
  mixins: [PlutonBaseMixin],
  data() {
    return {
      personal_additional_phones_count: 5,
      months: 3,
      pickup_locations: [],
      password_change: {
        current_password: '',
        password: '',
        state: true,
      },
      personal_details: {
        email: '',
        first_name: '',
        last_name: '',
        tel_mobile: '',
        tel_office: '',
        country_id: '',
        national_license: '',
        state_license: '',
        tel_additional_1: '',
        tel_additional_2: '',
        tel_additional_3: '',
        tel_additional_4: '',
        tel_additional_5: '',
      },
      showModal: false,
      msg: '',
      change_btn: true,
      busq: {
        ruta: '/api2/credentials/last_three_credentials',
        user_id: this.$auth.user().id,
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['role']),
    countriesAsOptions() {
      return this.$store.getters['countries/countriesEnabled'].map(
        (country) => ({
          value: country.id,
          text: country.name,
        })
      )
    },

    currentCountryName() {
      if (!this.personal_details.country_id) return null
      const country = this.countriesAsOptions.find(
        (c) => c.value === this.personal_details.country_id
      )
      if (!country) return null
      return country.text
    },
  },

  watch: {
    password_change: {
      deep: true,
      handler() {
        this.change_btn = !this.password_change.state
      },
    },
  },
  async mounted() {
    this.setLoading(true)

    try {
      const settingsResponse = await this.axios.get('/api/settings/me')
      this.setLoading(false)
      this.personal_details = settingsResponse.data.relation
    } catch (err) {
      this.catchAjaxError(err)
    }

    if (this.role !== 'doctor') return

    await this.getCountries()

    try {
      const pickupLocationResponse = await this.axios.get(
        '/api/pickup-location'
      )
      this.pickup_locations = [...pickupLocationResponse.data.rows]
    } catch (err) {
      this.catchAjaxError(err)
    }
  },
  validations: {
    personal_details: {
      first_name: { required },
      last_name: { required },
      tel_mobile: { required },
      country_id: { required },
      national_license: { required },
    },
  },
  methods: {
    ...mapActions('countries', ['getCountries']),

    get_password(val) {
      this.password_change.password = val.pass
      this.password_change.state = val.state
    },

    changePassword() {
      this.setLoading(true)
      if (!this.change_btn) {
        this.axios
          .post('/api/auth/change-password', {
            old_password: this.password_change.current_password,
            password: this.password_change.password,
            time: this.months,
          })
          .then((response) => {
            if (response.data.success) {
              this.setLoading(false)
              this.msg = 'La contraseña fue actualizada con éxito'
              this.showModal = true
            }
          })
          .catch(this.catchAjaxError)
      } else {
        this.alertFormError(
          'La contraseña debe cumplir con todos los requisitos.'
        )
      }
    },

    savePersonalDetails() {
      if (this.isValid()) {
        this.setLoading(true)
        this.axios
          .post('/api/settings/me', this.personal_details)
          .then((response) => {
            this.personal_details = response.data.relation
            this.$root.state.alerts.success_message =
              'Datos actualizados correctamente'
            this.setLoading(false)
          })
          .catch(this.catchAjaxError)
      } else {
        return this.alertFormError(
          'Por favor revise todos los campos e intente nuevamente'
        )
      }
    },

    async savePickupLocations() {
      if (!this.$refs.pickup_locations.isValid())
        return this.alertFormError(
          'Por favor revise que todos los campos estén completados correctamente'
        )
      this.setLoading(true)
      try {
        const promises = this.pickup_locations.map((pickup) => {
          let url = '/api/pickup-location'
          if (pickup.id) url = `${url}/${pickup.id}`
          return this.axios.post(url, pickup)
        })
        await this.axios.all(promises)
        this.setLoading(false)
      } catch (e) {
        this.catchAjaxError(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
