<template>
  <div class="form-group">
    <form-label v-if="label" :required="required"> {{ label }} </form-label
    ><br v-if="label" />
    <d-button-group :vertical="vertical" :size="size">
      <d-button
        v-for="opt in pickerOptions"
        :key="opt.value"
        :class="getClassForButtonOptions(opt.value, selectedOption)"
        :value="opt.value"
        :disabled="disabled"
        @click.stop.prevent="updateCondition"
      >
        {{ opt.text }}
      </d-button>
    </d-button-group>
  </div>
</template>

<script>
import { BREAKPOINTS } from '../utils'
import FormLabel from './Form/Label'

export default {
  name: 'MultiButtonPicker',
  components: {
    FormLabel,
  },
  props: {
    label: {
      type: String,
    },
    defaultValue: {
      type: String,
    },
    pickerOptions: {
      type: Array[Object],
    },
    required: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      selectedOption: this.defaultValue,
    }
  },
  computed: {
    vertical() {
      let screenWidth = window.innerWidth
      return screenWidth < BREAKPOINTS.md && this.pickerOptions.length > 3
    },
  },
  methods: {
    updateCondition: function (event) {
      this.selectedOption = event.target.value
      this.$emit('multi-button-picker-changed', this.selectedOption)
      this.$emit('change', this.selectedOption) // it's necesary have 2 indepent fields;
    },
    getClassForButtonOptions(option_value, value) {
      if (option_value == value) return 'btn-accent'
      return 'btn-white'
    },
  },
}
</script>

<style></style>
